<!--升级会员-->
<template>
  <!-- 头部菜单 -->
  <div style="overflow: auto;height: 100vh">
    <div>
      <PanelHeader class="head"></PanelHeader>
    </div>
    <div class="titleBox">
      <div class="toprq">
        <div class="title">
          <!--        <div :class="textShow===1?'textCol':'text'" @click="textShow=1">图片会员</div>-->
          <!--        <div :class="textShow===2?'textCol':'text'" @click="textShow=2">企业会员</div>-->
        </div>
        <div class="promotional">改字换图就能设计</div>
        <div class="functionIconBox">
          <diV class="box">
            <div class="name">商用版权保障</div>
          </diV>
          <diV class="box dianbox">
            <div class="names">.</div>
          </diV>
          <diV class="box">
            <div class="name">海量模版素材</div>
          </diV>
          <diV class="box dianbox">
            <div class="names">.</div>
          </diV>
          <diV class="box">
            <div class="name">不限次数下载</div>
          </diV>
          <diV class="box dianbox">
            <div class="names">.</div>
          </diV>
          <diV class="box">
            <div class="name">海量云端存储</div>
          </diV>
          <diV class="box dianbox">
            <div class="names">.</div>
          </diV>
          <diV class="box">
            <!--          <div class="imgCls">-->
            <!--            <i style="font-size: 35px" class="iconfont icon-tianjiawenzieditor"></i>-->
            <!--          </div>-->
            <div class="name">会员专属客服</div>
          </diV>
        </div>
      </div>

      <div class="optionBox">
        <div class="option" v-for="(item,index) in vip_list">
          <div v-if="item.top_state == 1">
            <div class="upper">
              <div v-if="item.cheap_state===1" class="subscript"
                   :class="index===1?'subscriptTwo':(index===2?'subscriptThree':(index===3?'subscriptFour':''))">
                {{ item.cheap_name }}
              </div>
              <div class="subject" :class="'subject'+index">{{ item.pro_name }} <span></span></div>
              <div class="price" :class="index===1?'priceTwo':(index===2?'priceThree':(index===3?'priceFour':''))">
                {{ item.price }}<span>元</span></div>
              <div class="originalPrice">原价{{ item.old_price }}元</div>
              <div class="button" :class="index===1?'buttonTwo':(index===2?'buttonThree':(index===3?'buttonFour':''))"
                   @click="getLogin(item.pro_name)">马上升级
              </div>
            </div>
            <div class="lower">
              <div>
                <div v-for="item2 in item.pro_list"><span class="detail">{{ item2.title }}</span><span
                    class="usable">{{ item2.remark }}</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
    <div class="imgBox">
      <img src="https://st0.dancf.com/static/02/202207280955-4167.png" alt="">
    </div>
    <div class="commercialInstructions">
      <h1>商用授权范围说明</h1>
    </div>
    <div class="commercialScope">
      <div class="commercialScope_table">
        <div class="onlineUse">
          <h3>线上用途</h3>
          <div class="contentBox" v-for="(item,index) in onlineUseList">
            <div class="contentBox_title"><i class="iconfont icon-huabanfuben"></i>{{ item.title }}</div>
            <span>{{ item.text }}</span>
          </div>
        </div>
        <div class="offlineUse">
          <h3>线下用途</h3>
          <div class="contentBox" v-for="(item,index) in offlineUseList">
            <div class="contentBox_title"><i class="iconfont icon-huabanfuben"></i>{{ item.title }}</div>
            <span>{{ item.text }}</span>
          </div>
        </div>
        <div class="disabledItems">
          <h3>禁用项</h3>
          <div class="contentBox" v-for="(item,index) in disabledItemsList">
            <div class="contentBox_title"><i style="color: #AAAAAA"
                                             class="iconfont icon-yuanquancuowu"></i>{{ item.title }}
            </div>
            <span>{{ item.text }}</span>
          </div>
        </div>
      </div>
    </div>
    <footer class="panel-footer">
      <public-footer></public-footer>
    </footer>
    <open-menber :openMembershipValue="openData" :openMenberList="openMenberList" :key="openData"></open-menber>
  </div>
</template>
<script lang="ts" setup>
import PanelHeader from "../../../layout/components/PanelHeader";
import OpenMenber from "./openMenber";
import {getPriceList} from "../../api/apiconfig/main";
import { getLocal } from "@/src/utils/local";
import { useLoginStore } from '@/src/store/modules/login'
import PublicFooter from "@/src/components/PublicFooter/index";
const textShow = ref(1);
const openData = ref({value: ''});
const list = ref([])
const vip_list = ref([]);
const openMenberList = ref([])
const openList = ref([])

useHead({
  title:'会员介绍页面_松鼠快图_松鼠快图官网_在线图片编辑_在线作图网站',
  meta:[
    {hid:'keywords',name:'keywords','content':'会员介绍,松鼠快图'},
    {hid:'description',name:'description','content':'松鼠快图会员介绍页面。'},
  ]
})


const getLogin = (row: any) => {
  if(getLocal('token')){
    openData.value = {value: row};
    openMenberList.value = openList.value
  }else{
    const loginStore = useLoginStore()
    loginStore.openloginstate()
  }
}
onMounted(() => {
  priceList()
})
//获取升级会员/支付数据(接口)
const priceList = (async () => {
  const response = await getPriceList()
  if (response.data.length !== 0) {
    list.value = []
    openList.value = response.data
    let person = response.data.person
    let company = response.data.company
    list.value = person.concat(company);
    list.value.forEach((item, index) => {
      if(Number(item.top_state) == Number(1)){
        //console.log(item.top_state,item.name)
        vip_list.value.push(item);
      }
    })
  } else {
    list.value = []
  }
})
//线上用途
const onlineUseList = ref([
  {title: "新媒体", text: "微信公众号、朋友圈、小红书、抖音等新媒体平台配图", id: 1,},
  {title: "网络推广", text: "百度推广、直通车等线上媒体渠道投放的配图", id: 2,},
  {title: "电商平台", text: "淘宝、京东、美团等电商交易平台配图", id: 3,},
  {title: "视频平台", text: "抖音、快手等网络视频播放平台配图", id: 4,},
  {title: "网络设计用途", text: "网站设计、H5、小程序等配图", id: 5,},
])
//线下用途
const offlineUseList = ref([
  {title: "线下活动", text: "年会、展出等线下活动", id: 1,},
  {title: "图书出版(50万份内)", text: "报纸、杂志、图书等封面及配图", id: 2,},
  {title: "线下印刷(50万份内)", text: "海报、宣传册、明信片、折页等", id: 3,},
  {title: "文化衍生品(非转售)", text: "T恤、门店显示屏幕、纺织品、马克杯等", id: 4,},
  {title: "商业提案(非转售)", text: "VI、营销方案等的制作", id: 5,},
])
//禁用项
const disabledItemsList = ref([
  {title: "户外广告", text: "户外广告牌", id: 1,},
  {title: "转售设计", text: "模板、主题、壁纸等", id: 2,},
  {title: "包装设计", text: "食品、电子产品等产品的包装设计", id: 3,},
  {title: "敏感用途", text: "酒精、医美、保健类、烟草广告等敏感类广告的宣传", id: 4,},
  {title: "商标注册", text: "商标、LOGO等", id: 5,},
])
</script>
<style lang="less" scoped>
.subject0{
  //background: linear-gradient(104deg, #ecf1fb 1.63%, #d5dcec 99.05%);
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
a {
  color: inherit; /* 文字颜色继承父元素 */
  text-decoration: none; /* 去除下划线 */
}

.toprq{
  width: 100%;
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  justify-content: center;
  background-image: url("/img/vipbg.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.titleBox {
  user-select: none;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  padding-top: 50px;
  //   margin-top:200px;
  // height: 1418px;
  z-index: 0;

  .title {
    width: 557px;
    //background: #fefdfc;
    //box-shadow: 0 5px 17px 1px rgba(238, 215, 213, 0.91);
    padding: 13px 14px;
    margin-top: 72px;
    border-radius: 46px;
    display: flex;
    justify-content: center;
    cursor: pointer;

    .textCol {
      font-size: 26px;
      text-align: center;
      font-weight: 700;
      line-height: 65px;
      width: 264.5px;
      height: 65px;
      background: linear-gradient(0deg, #ff774b, #ff8b69);
      -webkit-box-shadow: 3px 4px 51px 0 rgba(253, 78, 61, 0.71);
      box-shadow: 3px 4px 51px 0 rgba(253, 78, 61, 0.71);
      color: #FFFFFF;
      border-radius: 70px;
    }

    .text {
      font-size: 26px;
      text-align: center;
      font-weight: 700;
      line-height: 65px;
      width: 264.5px;
      height: 65px;
      //background: #FFFFFF;
      //background: linear-gradient(0deg, #ff774b, #ff8b69);
      //-webkit-box-shadow: 3px 4px 51px 0 rgba(253, 78, 61, 0.71);
      //box-shadow: 3px 4px 51px 0 rgba(253, 78, 61, 0.71);
      border-radius: 70px;
      color: rgba(253, 78, 61, 0.71);
    }
  }

  .promotional {
    width: 1200px;
    height: 126.27px;
    color: #262b34;
    font-weight: 800;
    font-size: 45.36px;
    text-align: center;
    letter-spacing: 20px;
  }

  .functionIconBox {
    width: 1200px;
    height: 102.19px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 120px;
    .dianbox{
      width: 10px !important;
      position: relative;
      .names{
        position: absolute;
        top:-4px;
      }
    }
    .box {
      width: 140px;
      height: 84.69px;
      display: flex;
      align-items: center;
      flex-direction: column;

      .imgCls {
        width: 46.89px;
        height: 46.89px;
        margin-bottom: 1.2rem;
      }

      .name {
        width: 156px;
        height: 21px;
        text-align: center;
        color: #505a71;
        font-size: 16px;
      }
    }
  }

  .optionBox {
    width: 1200px;
    height: 608.58px;
    margin: -100px 0 0;
    display: flex;
    justify-content: space-between;

    .option:hover {
      transform: translateY(-20px);
    }

    .option {
      width: 276px;
      height: 608.58px;
      border: 1px solid #f0f0f0;
      transition: transform 0.3s ease;
      transform: translateY(0);
      border-radius: 20px;
      box-shadow: 0 20px 40px rgba(42, 49, 67, .06);
      background: #fff;
      .upper {
        width: 100%;
        height: 235.45px;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        .subscript {
          position: absolute;
          right: -1px;
          top: -1px;
          background-color: #ff5642;
          font-weight: 500;
          height: 22px;
          font-size: 14px;
          line-height: 22px;
          padding: 0 10.5px;
          color: #fff;
          text-align: center;
          border-top-right-radius: 20px;
          border-bottom-left-radius: 20px;
        }

        .subject {
          width: 100%;
          height: 84px;
          padding: 37px 0 0;
          font-weight: 700;
          line-height: 1em;
          font-size: 23.69px;
          color: #1f252e;

          span {
            color: #ff705a;
            font-size: 14px;
            text-align: center;
            width: 119.55px;
            height: 13px;
          }
        }

        .subscriptTwo {
          background-color: #ff9046;
        }

        .subscriptThree {

          background-color: #ecc229;

        }

        .subscriptFour {
          background-color: #ff8997;
        }

        .price {
          //width: 88.95px;
          height: 44.8px;
          font-size: 36.96px;
          color: #f1504c;

          span {
            color: #ddb194;
            font-size: 17.92px;
            text-align: center;
          }
        }

        .priceTwo {
          color: #fb8f46;

          span {
            color: #fb8f46;
          }
        }

        .priceThree {
          color: #e3b412;

          span {
            color: #e3b412;
          }
        }

        .priceFour {
          color: #fb8896;

          span {
            color: #fb8896;
          }
        }

        .originalPrice {
          width: 100%;
          height: 21px;
          color: #000000a6;
          font-size: 14px;
          text-decoration: line-through;
          margin-top: 10px;
        }

        .button {
          cursor: pointer;
          margin-top: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 230px;
          height: 49px;
          color: #ffffff;
          font-weight: 700;
          margin-bottom: 0px;
          background: linear-gradient(99deg, #ff4837, #ff8c6d);
          border-radius: 14px;
          background-color: #da4a46;
          box-shadow: 0 3px 18px 0 rgba(0, 0, 0, 0.08);
          border-image: linear-gradient(0deg, #fff, #fff) 10 10;
        }

        .buttonTwo {
          background: linear-gradient(99deg, #ff9046, #fdc87a);
        }

        .buttonThree {
          background: linear-gradient(99deg, #f3c629, #fbe2a4);

        }

        .buttonFour {
          background: linear-gradient(99deg, #ff8997, #feb8c3);
        }
      }

      .lower {
        width: 230px;
        height: 371.13px;
        margin: 20px 22px;

        div {
          div {
            margin: 20px 0;
            font-size: 14px;

            .detail {
              color: #999999;
            }

            .usable {
              float: right;
              color: #444444;
            }
          }
        }
      }
    }
  }
}

.imgBox {
  margin: 20px 0;

  img {
    width: 100%;
  }
}

.commercialInstructions {
  display: flex;
  justify-content: center;
}

.commercialScope {
  display: flex;
  justify-content: center;
  width: 100%;
  user-select: none;

  .contentBox {
    width: 252px;
    margin-bottom: 20px;

    .contentBox_title {
      font-size: 16px;
      font-weight: 500;
      color: #33383e;

      i {
        color: #3DC526;
        font-size: 20px;
        margin-right: 10px;
      }
    }

    span {
      font-size: 12px;
      font-weight: 400;
      color: #636c78;
    }
  }

  .commercialScope_table {
    width: 1200px;
    height: 590px;
    margin: 50px 0;
    display: flex;
    justify-content: center;

    .disabledItems {
      padding: 30px 24px;
      width: 300px;
      background-color: #F6F7F9;
      border-top-right-radius: 18px;
      border-bottom-right-radius: 18px;
      border-bottom: 1px solid #e8eaec;
      border-right: 1px solid #e8eaec;
      border-top: 1px solid #e8eaec;
    }

    .onlineUse {
      padding: 30px 24px;
      width: 300px;
      border-top-left-radius: 18px;
      border-bottom-left-radius: 18px;
      border: 1px solid #e8eaec;
    }

    .offlineUse {
      padding: 30px 24px;
      width: 300px;
      //background-color: #60748c;
      border-bottom: 1px solid #e8eaec;
      border-right: 1px solid #e8eaec;
      border-top: 1px solid #e8eaec;
    }
  }
}

.under {
  width: 100%;
  height: 41px;
  background: #000000;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    font-size: 14px;
    color: #aaaaaa;
  }
}

.head {
  position: fixed;
}
</style>
