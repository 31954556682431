<template>
  <client-only>
    <el-dialog  align-center :show-close="false" :close-on-click-modal="false" :closable="false"
               class="openMenbershipDialog" style="width: 900px;"
               footer-hide="false"
               v-model="modal" title="">
      <div class="modalBox">
        <!--        头部-->
        <div :class="menuShow?'headBack':'headBack2'" class="head">
          <div class="head_left">
            <img src="/img/cover_head.png"
                 alt="">
            <div class="head_left_textBox">
              <div class="head_left_textBox_top">
                <div>{{userGroup}}</div>
              </div>
            </div>
          </div>
          <div class="head_right">
            <div class="colse" @click="modal=false">
              <i style="color: #868686;font-size: 20px;cursor: pointer" class="iconfont icon-guanbi"></i>
            </div>

          </div>
        </div>
        <!--        菜单-->
        <div class="menuBox" :style="{backgroundColor:menuShow?'rgb(243 248 255)':'rgb(255 247 241)'}">
          <div class="menuBox_left" @click="getChoice(true)"><span :style="{color:menuShow?'':'#1b2337'}">个人版</span>
          </div>
          <div class="menuBox_right" @click="getChoice(false)"><span :style="{color:!menuShow?'':'#1b2337'}">企业版</span>
          </div>
          <div :class="menuShow?'menuBox_left_img':'menuBox_right_img'"></div>
        </div>
        <!--        内容-->
        <div class="content">
          <!--          左面-->
          <div class="content_left">
            <div class="content_left_text" v-for="item in currency">
              <div class="content_left_text_vip">
                <i :style="{color:menuShow?'#0773fc':'#febf93'}"
                   class="iconfont icon-vip1"></i><span>{{ item.title }}</span>
              </div>
              <div>{{ item.explain }}</div>
              <div class="line"></div>
              <div :style="{color:menuShow?'#0773fc':'#6b3d1e'}" class="tagging">{{ item.content1 }}</div>
              <div>{{ item.content2 }}</div>
              <div>{{ item.content3 }}</div>
              <div>{{ item.content4 }}</div>
              <div>{{ item.content5 }}</div>
              <div>{{ item.content6 }}</div>
            </div>
            <div class="content_left_contrast">
              <div class="content_left_contrast_box">
                <img class="image" :src="qrcodeImg" alt=""></img>
                <span>支付遇到问题?<br>扫码联系客服</span>
              </div>
            </div>
          </div>
          <!--          右边-->
          <div class="content_right">
            <div class="content_right_title">
              <div v-if="menuShow">授权主体为个人，支持个人商用</div>
              <div v-else>授权主体为企业/个体户，支持企业商用</div>
            </div>
            <div class="content_right_optionBox">
              <div class="content_right_option"
                   :style="{outline:menuShow?choiceId===index && memberSelectionId!==index?'2px solid #0773fc':'':(choiceId===index && memberSelectionId!==index?'2px solid #febf93':'')}"
                   @mouseenter="getMouseenter(index)" @mouseleave="getMouseLeave(index)"
                   :class="menuShow?memberSelectionId===index?'memberSelectionColor':'':(memberSelectionId===index?'memberSelectionColor2':'')"
                   @click="getMemberSelection(index,item)" v-for="(item,index) in memberSelectionData">
                <div class="stateCla" v-if="item.state">{{ item.state }}</div>
                <div class="termCla">{{ item.name }}</div>
                <div class="priceCla">¥ <span>{{ item.price }}</span> /人</div>
                <div class="originalPriceCla">原价¥ {{ item.old_price }} /人</div>
                <div
                    :class="menuShow?memberSelectionId===index?'averageColor':'':(memberSelectionId===index?'averageColor2':'') "
                    class="averageCla">{{ item.count_price }}
                </div>
              </div>
            </div>
            <div class="qrCodeBox">
              <div class="tableLine_title">套餐详情</div>
              <div class="tableLine">
                <div class="tableLine_row">
                  <div>会员到期日期</div>
                  <div>{{endTime}}</div>
                </div>
              </div>
              <div class="tableLine">
                <div class="tableLine_row">
                  <div>优惠价格</div>
                  <div>¥ {{ discountAmount }}</div>
                </div>
              </div>
              <div class="tableLine">
                <div class="tableLine_row">
                  <div>实付金额</div>
                  <div>¥ <b>{{ totalAmount }}</b></div>
                </div>
              </div>
              <div class="invoice"><b>*</b> 消费后可在[个人中心 ->申请发票] 申请增值税普通发票</div>
              <div class="tableLine_title">支付方式</div>
              <div class="termValidity">
                <div class="payment alipay" @click="getPayment('支付宝',1)"> <i class="iconfont icon-zhifubao1"></i> 支付宝支付</div>
                <div class="payment wehAt" @click="getPayment('微信',2)"> <i class="iconfont icon-wxpay"></i> 微信支付</div>
              </div>
              <div class="qrCodeImg">
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </client-only>
  <client-only>
    <el-dialog align-center :show-close="false" :close-on-click-modal="false" :closable="false"
               class="changeWeChatDialog" @close="getPaymentClose" style="width: 370px"
               footer-hide="false" v-model="scanTheCode">
      <div class="changeWeChat">
        <div class="changeWeChatColse" @click="scanTheCode=false">
          <i style="color: #868686;font-size: 20px;cursor: pointer" class="iconfont icon-guanbi"></i>
        </div>
        <div class="changTitle">
          <i :style="{color:paymentId==='支付宝'?'#009FE8':'#24B340'}"
             :class="paymentId==='支付宝' ?'iconfont icon-zhifubaozhifu1': 'iconfont icon-weixinzhifu' "></i>
          <span>使用{{ paymentId }}支付</span>
          <div><span class="money">¥ <b>{{totalAmount}}</b></span></div>
        </div>
        <img class="weChatImage" :src="imgData" alt="">
      </div>
    </el-dialog>
  </client-only>
</template>
<script setup>
import {ref} from "vue";
import {getCheckGroupAuth, getMemberPayInfo, getOrderStatus, getPassageway} from "../../api/apiconfig/main";
import { ElMessage } from 'element-plus'

const userGroup = ref()
const qrcodeImg = ref()
const endTime = ref()
const menuShow = ref(true);
const paymentId = ref('')
const imgData = ref('/img/qr-loading.gif')
const scanTheCode = ref(false);
const choiceId = ref(0);
const memberSelectionId = ref(0)
const modal = ref(false);
const menuData = ref()
const memberSelectionData = ref([]);
const totalAmount = ref(0.00)
const discountAmount = ref(0.00)
const currency = ref([])
const timingNewImg = ref()
const timing = ref()
const paymentType = ref()
const proType = ref()
const props = defineProps({
  openMembershipValue: {},
  openMenberList: {},
})
const getChoice = ((show) => {
  menuShow.value = show
  if (show) {
    currency.value = [
      {
        title: "个人版",
        explain: '适用于个人非工商注册主体/工商注册主体使用',
        content1: '个人商用授权',
        content2: '线上+线下商用授权',
        content3: '无水印下载',
        content4: '精美原创模板',
        content5: 'VIP专属素材',
        content6: 'VIP专属模板',
      }
    ]
    memberSelectionData.value = menuData.value.person
  } else {
    currency.value = [
      {
        title: "企业版",
        explain: '适用共享协作需求的团队/企业使用',
        content1: '企业/个体户商用授权',
        content2: '线上/线下商用授权',
        content3: '无水印下载',
        content4: '精美原创模板',
        content5: 'VIP专属素材',
        content6: 'VIP专属模板',
      }
    ]
    memberSelectionData.value = menuData.value.company
  }
  getMemberSelection(0, memberSelectionData.value[0],)
})
//鼠标悬浮
const getMouseenter = ((index) => {
  choiceId.value = index
})
//鼠标离开
const getMouseLeave = ((index) => {
  choiceId.value = -1
})
//支付方式选择
const getPayment = ((data,type) => {
  checkGroupAuth(data,type)

})
//选择产品权限是否可以进行支付
const checkGroupAuth = (async (data,type)=>{
  const response = await getCheckGroupAuth({pro_type:proType.value})
  if(response.code===1){
    paymentId.value = data
    paymentType.value = type
    scanTheCode.value = true
    modal.value = false
    passageway()
  }else{
    getClearTiming()
    ElMessage({
      message: response.msg,
      grouping: true,
      customClass: 'my-custom-message-style',
      type: 'error',
    })
  }

})
//获取支付二维码
const passageway  = (async ()=>{
  //支付宝1分钟 微信3分钟二维码过期
  //清空定时器
  if (timing.value) {
    clearInterval(timing.value)
  }
  if (timingNewImg.value) {
    clearInterval(timingNewImg.value)
  }
  const response = await getPassageway({type:paymentType.value,pro_type:proType.value})
  if(response.data){
    imgData.value = response.data
    //每隔2秒获取一次状态
    timing.value = setInterval(() => {
      orderStatus()
    }, 2000)
    //1分钟不操作自动关闭弹窗
    timingNewImg.value = setInterval(() => {
      scanTheCode.value = false
    }, 60000)
  }
})
//获取支付状态
const orderStatus = (async ()=>{
  const response = await getOrderStatus()
  if(response.code==1){
    ElMessage.success(response.msg)
    scanTheCode.value = false
  }else{
  }
})
//清空定时器
const getClearTiming = (() => {
  clearInterval(timing.value)
  clearInterval(timingNewImg.value)
})
//初始化
onMounted(() => {
  getModal()
});
const getModal = () => {
  if (props.openMembershipValue.value) {
    menuData.value = props.openMenberList
    modal.value = true;
    if (props.openMembershipValue.value === '企业会员') {
      getChoice(false)
    } else {
      getChoice(true)
    }
  }
}
//选择开通会员
const getMemberSelection = ((index, value) => {
  proType.value = value.id
  memberSelectionId.value = index
  totalAmount.value = Number(value.price)
  discountAmount.value = Number(value.old_price) - Number(value.price)
  memberPayInfo()

})
//获取会员到期时间
const memberPayInfo = (async ()=>{
  const response = await getMemberPayInfo({pro_type:proType.value})
  if(response.data){
    endTime.value = response.data.end_time
    qrcodeImg.value = response.data.qrcode
    userGroup.value = response.data.user_group
  }
})
//支付窗口关闭
const getPaymentClose = (()=>{
  getClearTiming()
})
</script>
<style>
.my-custom-message-style{
  z-index: 20010 !important;
  user-select: none;
}
.openMenbershipDialog {
  --el-dialog-bg-color: unset !important;
  --el-dialog-box-shadow: unset !important;
}
.changeWeChatDialog{
  --el-dialog-bg-color: unset !important;
  --el-dialog-box-shadow: unset !important;
}
</style>
<style lang="less" scoped>
.modalBox {
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border-radius: 16px;
  user-select: none;

  .headBack {
    background-image: url("https://www.chuangkit.com/distweb/img/background1.4e1dd6b0.png");
  }

  .headBack2 {
    background-image: url("https://www.chuangkit.com/distweb/img/background3.800e5b87.png");
  }

  .head {
    height: 42px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    display: flex;
    justify-content: space-between;
    padding: 8px 24px 16px;

    .head_left {
      user-select: none;
      display: flex;

      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid hsla(0, 0%, 100%, .3);
      }

      .head_left_textBox {
        margin-left: 0px;
        display: flex;
        align-items: center;
        .head_left_textBox_top {
          display: flex;

          span {
            padding: .5px 8px;
            font-size: 14px;
            font-weight: 500;
          }

          div {
            height: 20px;
            margin-left: 8px;
            padding: .5px 5px;
            border-radius: 4px;
            font-size: 12px;
            line-height: 20px;
            border: .5px solid hsla(0, 0%, 100%, .3);
            background: hsla(0, 0%, 100%, .5);
          }
        }

        .head_left_textBox_bottom {
          color: #505a71;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          padding: 0 8px;
        }
      }
    }

    .head_right {
      display: flex;
      align-items: center;
      position: relative;

      div {
        color: #505a71;
        font-size: 14px;
        line-height: 22px;
      }

      span {
        padding: 0 10px;
      }

      .colse {
        width: 40px;
        height: 40px;
        background-color: #FFFFFF;
        position: absolute;
        top: 0;
        right: -75px;
        cursor: pointer;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
          z-index: 2;
        }
      }
    }
  }

  .menuBox {
    user-select: none;
    height: 48px;
    position: relative;
    background-color: #FFFFFF80;

    .menuBox_left {
      float: left;
      width: 50%;
      height: 43px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      cursor: pointer;

      span {
        z-index: 1;
        opacity: 1;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        color: #0773fc;

      }
    }

    .menuBox_right {
      float: left;
      width: 50%;
      height: 43px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      span {
        z-index: 1;
        opacity: 1;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        color: #f49e5c;

      }
    }

    .menuBox_left_img {
      width: 50%;
      position: absolute;
      left: 0;
      bottom: -5px;
      height: 56px;
      content: "";
      background-size: 100%;
      background-repeat: no-repeat;
      background-image: url("/img/tab1.svg");
    }

    .menuBox_right_img {
      width: 50%;
      position: absolute;
      right: 0;
      bottom: -5px;
      height: 56px;
      content: "";
      background-size: 100%;
      background-repeat: no-repeat;
      background-image: url("/img/tab2.svg");
    }
  }

  .content {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    padding: 16px 24px 22px;
    display: flex;

    .content_left {
      width: 194px;
      height: 480px;
      min-height: 480px;
      position: relative;
      background: #f5f7fd;
      padding: 12px 16px 16px 16px;
      border-radius: 12px;

      .content_left_text {
        span {
          font-weight: 500;
          color: #1b2337;
          font-size: 16px;
          line-height: 24px;
        }

        div {
          font-size: 12px;
          font-weight: 400;
          color: #505a71;
          margin-bottom: 8px;
          line-height: 28px;
          text-align: center;

        }

        .line {
          background: #EBEEF5;
          height: 1px;
          width: 100%;
        }

        .tagging {
          color: #0773fc;
          font-size: 12px;
          line-height: 20px;
          margin-top: 15px;
        }

        .content_left_text_vip {
          i {
            font-size: 17px;
            margin-right: 8px;
          }
        }
      }

      .content_left_contrast {
        position: absolute;
        bottom:-35px;
        left: 50%;
        transform: translate(-50%, -50%);
        .content_left_contrast_box{
          display: flex;
          align-items: center;
          flex-direction: column;
          .image{
            width: 57px;
            height: 57px;
            margin-bottom: 10px;
          }
          span{
            width: 93px;
          }
        }
      }
    }

    .content_right {
      width: 100%;
      margin-left: 25px;
      user-select: none;
      height: 525px;
      .content_right_title {
        display: inline-block;
        line-height: 32px;
        font-size: 12px;
        font-weight: 400;
        color: #505a71;
        padding-left: 10px;
        width: 100%;
        border-radius: 5px;
        letter-spacing: 2px;
        background: linear-gradient(90deg, rgba(7, 115, 252, .06), rgba(7, 115, 252, 0));
      }

      .numberSeats {
        display: flex;
        align-items: center;

        span {
          color: #505a71;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          letter-spacing: 0;
          text-align: left;
          display: flex;
          align-items: center;
          margin-right: 40px;
        }
      }

      .content_right_optionBox {
        padding-top: 24px;
        display: flex;

        .content_right_option_hover {
          border: 1px solid #0773fc;
        }

        .memberSelectionColor {
          background: #f5faff;
          outline: 2px solid #0773fc;
          box-sizing: border-box;
          height: 150px;
        }

        .memberSelectionColor2 {
          background: #f5faff;
          outline: 2px solid #febf93;
          box-sizing: border-box;
          height: 170px;
        }

        .content_right_option {
          width: 146px;
          margin-right: 15px;
          border: 1px solid rgba(0, 0, 0, .09803921568627451);
          display: flex;
          align-items: center;
          flex-direction: column;
          position: relative;
          border-radius: 12px;
          color: #1b2337;
          cursor: pointer;

          .stateCla {
            position: absolute;
            top: -12px;
            right: -2px;
            margin: 0 8px;
            height: 24px;
            background: linear-gradient(90deg, #ff6464 2.95%, #fa2323 99.68%);
            border-radius: 12px 8px 8px 4px;
            font-size: 12px;
            color: #fff;
            text-align: center;
            line-height: 24px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 100%;
          }

          .termCla {
            margin: 16px 0 12px 0;
            width: 146px;
            overflow: hidden;
            white-space: nowrap;
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
            text-align: center;
          }

          .priceCla {
            font-size: 12px;
            margin-left: 4px;
            color: #1b2337;

            span {
              color: #1b2337;
              font-size: 24px;
              margin: 0 2px;
              line-height: 32px;
              font-weight: bolder;
            }
          }

          .originalPriceCla {
            font-size: 12px;
            color: rgba(27, 35, 55, .4);
            line-height: 20px;
            font-weight: 400;
            margin-top: 4px;
            text-decoration: line-through;
          }

          .averageCla {
            bottom: 0;
            position: absolute;
            background: #f3f4f9;
            border-radius: 0 0 10px 10px;
            width: 100%;
            height: 24px;
            font-size: 12px;
            line-height: 24px;
            font-weight: 400;
            text-align: center;
          }

          .averageColor {
            background-color: #0773fc;
            color: #FFFFFF;
          }

          .averageColor2 {
            background-color: #febf93;
            color: #1b2337;
          }
        }

      }

      .authorizationType {
        height: 36px;
        display: flex;
        align-items: center;
        margin: 12px 0;

        .authorizationType_title {
          font-size: 14px;
          color: rgba(0, 0, 0, .65);
        }

        .authorizationType_radio {
          margin-left: 32px;
        }

        .authorizationType_originalPrice {
          color: #8693ab;
          text-decoration: line-through;
          margin: 0 8px;
          font-size: 14px;
        }

        .authorizationType_price {
          color: #fa2323;
          font-size: 14px;
        }

        .authorizationType_label {
          display: inline-block;
          width: 60px;
          height: 20px;
          line-height: 20px;
          text-align: center;
          font-size: 12px;
          background: #fee9e9;
          color: #fa2323;
          border-radius: 4px;
          margin-left: 8px;
        }
      }

      .discount {
        display: flex;
        align-items: center;
        border-radius: 4px;
        background: #f3f4f9;
        height: 32px;
        padding: 0 16px;
        margin: 12px 0;
        color: #1b2337;
        font-weight: 700;
        font-size: 12px;

        span {
          color: #1b2337;
          font-size: 12px;
          line-height: 20px;
        }
      }

      .qrCodeBox {
        .tableLine_title {
          margin: 15px 0;
          font-size: 16px;
          font-weight: 1000;
          color: #1b2337;
        }

        .tableLine {
          background-color: #FAFAFA;
          height: 40px;
          display: flex;
          align-items: center;
          padding: 0 20px;

          .tableLine_row {
            border-bottom: 1px #EDEDED solid;
            height: 39px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            b {
              color: red;
              font-size: 20px;
            }
          }
        }

        .invoice {
          background-color: #FAFAFA;
          margin: 10px 0;
          b{
            color: red;
          }
        }

        .termValidity {
          display: flex;
          margin-top: 10px;

          .payment {
            width: 50%;
            margin-right: 15px;
            height: 40px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #FFFFFF;
            font-size: 15px;
            font-weight: 700;
            border: 1px solid rgba(0, 0, 0, .09803921568627451);
            cursor: pointer;
          }

          .alipay:hover {
            background-color: rgba(0, 159, 232, 0.8);
          }

          .alipay {
            background-color: rgba(0, 159, 232);
            i{
              margin-right: 8px;
            }
          }

          .wehAt:hover {
            background-color: rgba(36, 179, 64, 0.8);
          }

          .wehAt {
            background-color: rgba(36, 179, 64);
            i{
              margin-right: 8px;
            }
          }

        }
      }
    }
  }
}

.changeWeChat {
  border-radius: 6px;
  height: 350px;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  user-select: none;
  position: relative;

  .weChatImage {
    width: 70%;
  }

  .changTitle {
    display: flex;
    top: 25px;
    position: absolute;
    i {
      font-size: 20px;
    }

    span {
      margin-left: 10px;
      font-size: 16px;
      font-weight: 700;
      margin-right: 10px;
    }
    .money{
      b{
        color: red;
        font-size: 16px;
      }

    }
  }
  .changeWeChatColse {
    width: 40px;
    height: 40px;
    background-color: #FFFFFF;
    position: absolute;
    top: 5px;
    right: -50px;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      z-index: 1;
    }
  }
}
</style>